import React, { Component } from 'react'
import gsap from 'gsap';



const SVG = ({className}) => (<svg className={className} id="Livello_1"  viewBox="0 0 76 75" xmlns="http://www.w3.org/2000/svg">);
<defs>
    <linearGradient x1="84.766%" y1="0%" x2="38.195%" y2="78.067%" id="a">
        <stop  stop-color="#B942B8" offset="0%"/>
        <stop stop-color="#CA83F1" offset="100%"/>
    </linearGradient>
    <linearGradient x1="80.316%" y1="20.397%" x2="30.656%" y2="76.69%" id="b">
        <stop stop-color="#D42200" stop-opacity=".677" offset="0%"/>
        <stop stop-color="#F76F00" stop-opacity=".765" offset="100%"/>
    </linearGradient>
</defs>
<g transform="translate(0 3)" fill="none" fill-rule="evenodd">
    <rect fill="#FFF" x="27" width="46" height="46" rx="17"/>
    <circle fill="url(#a)" cx="26.5" cy="45.5" r="26.5"/>
    <rect stroke="#000" stroke-width="5" fill="url(#b)" x="27" width="46" height="46" rx="17"/>
</g>
</svg>)
export default class Logo extends Component {

    logoRef = null;
    // componentDidMount() {

    //     // const { animated } = this.props;        
       
    // }

    
    render() {

        if(this.props.logo)
            return (<SVG className={this.props.className} />)


        return (
            <div className="text-white flex items-center">
                <SVG className="md:w-1/12 w-2/12" />
                <span className="md:text-4xl text-2xl tracking-tight ml-3 font-mediumstr">enigma</span>
            </div>
            
        )
    }
}
