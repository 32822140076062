/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"
import gsap from 'gsap'
import useMouse from '@react-hook/mouse-position';
import { forwardRef, useRef, useEffect } from "react"
import { TweenLite, TweenMax } from "gsap/all"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Logo from "./Logo";

const styles = {
  circle: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: 'white',
    position: 'absolute',
    zIndex:100,
    'pointer-events': 'none'
  },
  wrapper: {
    width: '100vw',
    height: '100vh',
    position: 'relative',
  },
  inner: {
    width: '100%',
    height: '100%',
  },
};
const Circle = forwardRef((props, ref) => {
  return <div className="flex items-center justify-center shadow-md border border-gray-200" style={styles.circle} ref={ref}>
    {props.children}
  </div>;
});





// Transition Link Component
export const ListTLink = props => (
    <span>{props.children}</span>

)

// hidden object animation
export function animateObjects() {
  var tl = gsap.timeline();
  tl.to('ul.transition li', {duration: .5, scaleY: 1, transformOrigin: "bottom left", stagger: .2})
  tl.to('ul.transition li', {duration: .5, scaleY: 0, transformOrigin: "bottom left", stagger: .1, delay: .1})
}

// new content to be faded in after animation
export function newContent(node) {
  return gsap.from(node.querySelectorAll('h1, h2, h3, h4, p, a, img, table, ul, pre'),
  {
    opacity: 0,
    delay: 1,
    duration: 2,
    stagger: 0.08,
  })
}

const Layout = ({ children, location, mouseHoverState }) => {


  let mouseTracker = useRef();
  let circleRef = useRef();
  let inner = useRef();
  let speed = 0.8;
  let fpms = 60 / 1000;
  let pos = useRef(null);
  let xSet = useRef();
  let ySet = useRef();
  let mousePosWindow = useRef();
  let cursorIconRef = useRef();


  mousePosWindow.current = { x: 0, y: 0 }
  pos.current = {
    x: 0,
    y: 0,
  };

  useEffect(() => {

    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    
    
    pos.current = {
      x: inner ? inner.offsetWidth / 2 : 100,
      y: inner ? inner.offsetHeight / 2 : 100,
    };
    mousePosWindow.current = { x: pos.current.x, y: pos.current.y}

    gsap.set(circleRef.current, {xPercent: -50, yPercent: -50});
    xSet.current = gsap.quickSetter(circleRef.current, "x", "px");
    ySet.current = gsap.quickSetter(circleRef.current, "y", "px");
    //document.addEventListener('mousemove', (ev) => {mouseMoveEvent(ev)} );
    document.addEventListener('mousemove', (ev) => mousePosWindow.current = {
      x: ev.pageX,
      y: ev.pageY,

    } );

    gsap.ticker.add((time, deltaTime) => {

      var delta = deltaTime * fpms;
      var dt = 1.0 - Math.pow(1.0 - speed, delta); 
      pos.current.x += (mousePosWindow.current.x - pos.current.x) * dt;
      pos.current.y += (mousePosWindow.current.y - pos.current.y) * dt;

      // xSet.current(pos.current.x)
      // ySet.current(pos.current.y)

      TweenMax.to([circleRef.current], 0.3, {
        x: pos.current.x,
        y: pos.current.y,
        width:mouseHoverState.x * 25,
        height:mouseHoverState.x * 25,
        ease: 'Power3.easeOut'
      });

      TweenMax.to([cursorIconRef.current], 0.3, {
       opacity:mouseHoverState.x - 1 ,
        ease: 'Power3.easeOut'
      });

      
    });



    return () => {
      document.removeEventListener('mousemove', (ev) => mouseMoveEvent(ev));
    }
  }, [])

  const mouseMoveEvent = (ev) => {
    //console.log(mousePosWindow.current);
   // console.log(ev);


  }



  return (
    <>
      <div
      
        ref={mouseTracker}
        style={{
          minHeight:'100vh',
          background:'#000'
        }}
      >
        <Circle mouseHoverState={mouseHoverState.x} ref={circleRef}> 
      <svg ref={cursorIconRef} xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
</svg>
</Circle>
        <main
          ref={inner}
        >{children}
    
        </main>

        <div className='mt-24 max-w-screen-lg mx-auto text-white px-12'>
          <h1 className="text-white text-xl md:text-4xl font-bold">Love to work together?</h1>
          <h2 className="text-white text-xl md:text-4xl font-bold opacity-50 block">Drop us some info</h2>
          <a href="/contact" className="bg-white text-black py-3 px-4 font-semibold rounded-lg mt-4 inline-block cursor-pointer">Get in touch →</a>
      </div>


      <div className='mt-16 max-w-screen-lg mx-auto px-12 '>
        <div className="flex justify-between items-center border-t border-gray-800 py-16 ">
          <div className="address text-white">
            <h6 className="opacity-75 mb-2">Enigma Software Solutions Private Limited</h6>
            <p className="opacity-50 text-sm"> Padmavathi Industrial,<br />Koramangala <br /> Bangalore KA 560034</p>
            <p className="opacity-50 text-sm mt-4">Drop a note at athul @ madebyenigma.com</p>

          </div>
          <div>
            <Logo logo className="w-10" />
          </div>
        </div>
      </div>


        <div className="downArrow fixed" style={{ left:35, bottom:35 }}>
          <img src="/downArrow.svg"></img>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
